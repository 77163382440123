$(function() {
	var element, trigger;

	$('.js-faq-item').each(function(index, item) {
		new Faq($(item));
	});

	var scrollTrigger = $('.js-pulse');
	var element = $('#pulse');
	var hasPlayed = false;
	if(scrollTrigger.length) {
		$(window).on('scroll', function() {
			if(isScrolledIntoView(scrollTrigger) && !hasPlayed) {
				element.addClass('is-active');
				hasPlayed = true;
			}
		});
	}
});

var Faq = function(item) {
	this.active = false;
	this.item = item;
	this.item.on('click', this.click.bind(this));

	this.trigger = item.find('.js-faq-close');
	this.trigger.on('click', this.close.bind(this));
}

Faq.prototype.click = function() {
	if(!this.active) {
		this.active = true;
		this.item.addClass('is-active');
	}
}

Faq.prototype.close = function() {
	var currScope = this;
	setTimeout(function() {
		currScope.active = false;
		currScope.item.removeClass('is-active');
	}, 1);
}

$(function() {
	var element, target, _document;
	_document = $('html, body');
	_headerHeight = $('.c-header').outerHeight();
	$('[data-navigate]').on('click', function(e) {
		e.preventDefault();
		element = $(this);
		target = $(element.attr('href'));
		_document.animate({
			scrollTop: target.offset().top - _headerHeight
		}, 250 * (element.index() + 1) );
	});
});


$(function() {
	var _body = $('body');
	$('.c-meta-nav-trigger').on('click', function() {
		_body.addClass('is-menu-active is-menu-animating');
	});
	$('.c-meta__close').on('click', function() {
		_body.addClass('is-menu-active-out');
		setTimeout(function() {
			_body.removeClass('is-menu-active is-menu-animating is-menu-active-out');
		}, 300);
	});
	_body.keyup(function(e) {
		if(e.keyCode == 27) {
			_body.removeClass('is-menu-active is-menu-animating');
		}
	});
});


$(function() {
	var _meta = $('.js-meta');
	$('[data-color]').on('mouseenter', function() {
		_meta.stop(true, false).animate({
			backgroundColor: $(this).data('color')
		}, 300);
	});
});

function isScrolledIntoView(elem)
{
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();

    return ((elemBottom <= docViewBottom));
}

// -----------------------------------------------------------
// DOCUMENT ON READY 
// -----------------------------------------------------------
$(function() {
	if(!Modernizr.csstransforms3d) {
		$body.addClass('no-3d');
	}

	$window.on('resize', windowResize);
	windowResize();
	
	Menu = new Menu();
	Pagination = new Pagination();
	Districts = new Districts();
	Municipalities = new Municipalities();
	Search = new Search();
	Tabs = new Tabs();
	Poster = new Poster();

	new Filters('#filterCalendarList', '.grid-part-alt.article-content .section-sub');
	new Filters('#filterList', '.wiki-list');
	new Filters('#filterSmallColumnItems', '#filterSmallColumn');

	$('.acc-container').accordion();
	$('.js-lightbox').lightbox();
	$('.js-open-new-window').openNewWindow();

	$('.js-load-trigger').on('click', submitLoader);

});



// -----------------------------------------------------------
// POSTERGENERATOR 
// -----------------------------------------------------------



var Poster = function() {
	var typingTimer; //timer identifier
	var refreshFormInterval = 400;


	this.form = $('#file-form');
	if (!this.form.length) return;

	//Elements
	this.posterSelect = $('.poster-to-select');
	this.typeSelect = $('.type-to-select');
	this.fileSelect = $('.file-select');
	this.logoSelect = $('.file-select-logo');
	this.uploadButton = $('#upload-button');
	this.imgPathVal = $('.custom-poster-val');
	this.textArea = $('.txt-input');
	this.downloadPdfBtn = $('.download-pdf');

	//Actions
	this.form.on('submit', submitForm.bind(this));
	this.posterSelect.on('click', selectPoster.bind(this));
	this.typeSelect.on('click', selectType.bind(this));
	this.downloadPdfBtn.on('click', downloadPdf.bind(this));
	this.fileSelect.on('change', refreshForm);
	this.logoSelect.on('change', refreshForm);

	//on keyup, start the countdown
	this.textArea.on('keyup', function () {
	  clearTimeout(typingTimer);
	  typingTimer = setTimeout(refreshForm.bind(this), refreshFormInterval);
	});

	//on keydown, clear the countdown 
	this.textArea.on('keydown', function () {
	  clearTimeout(typingTimer);
	});

	//user is "finished typing," do something
	function refreshForm () {
	  //do something
	  submitForm();
	}

	function selectType(event) {
		var el = $(event.target).parent();
		var tab = el.data('tab');
		var typeForm = $('.poster-type').val();

		if(el.hasClass('selected')){
			return;
		}
		this.typeSelect.removeClass('selected');
		el.addClass('selected');
		//show type content

		$('.img-type').hide();
		$('#'+tab).show();
		
		//exception form layout for logo and txt input
		$('.txt-layout').show();
		$('.logo-layout').show();
		if(!$('#'+tab).data('txt')){
			$('.txt-layout').hide();
		}
		if(!$('#'+tab).data('logo')){
			$('.logo-layout').hide();
		}
		
		var newVal = typeForm.replace(typeForm.slice(-1),tab.slice(-1));
		$('.poster-type').val(newVal);

		var path = $('#'+tab).find('li').first().attr('data-poster');
		this.imgPathVal.val(path);

		refreshForm();
	}

	function selectPoster(event) {
		var el = $(event.target).parent();
		var path = el.data('poster');
		this.imgPathVal.val(path);

		$('.poster-to-select').removeClass('selected');
		el.addClass('selected');

		if ($(event.target).hasClass('upload-file')) {
			$(event.target).addClass('selected');
			this.fileSelect.click();

		}else{
			this.fileSelect.val("");
			refreshForm();
		}
	}

	function submitForm(event) {
		var form = $('#file-form');
    	var url = form.attr('action');
  		var request = new XMLHttpRequest();
  		var dataForm = new FormData(form[0]);

	    if (!request) {
	      alert('Giving up :( Cannot create an XMLHTTP instance');
	      return false;
	    }

		$.ajax({
          type: "POST",
          url: url,
          data: dataForm,
          //use contentType, processData for sure.
          contentType: false, 
          processData: false,
          	beforeSend: function() {
       			$('.container-preview').empty();
       			$('.preview').addClass('loading');
       			$('.errors').text("");
            },
            success: function(data) {
            	generatePreview(data);                   
            },
            error: function (jqXHR, json) {
            	var errors = jqXHR.responseJSON;
			    for (var error in errors) {
			    	if(error == 'count') return;
			        $('.errors').append(errors[error] + '<br>');
			    }
			}
        })

	}

	function downloadPdf (e) {
	  	e.preventDefault();
	 	var form = $('#file-form');
	  	var url = $(e.target).data('url');
		var request = new XMLHttpRequest();
		var dataForm = new FormData(form[0]);
		var txt;

	    if (!request) {
	      alert('Giving up :( Cannot create an XMLHTTP instance');
	      return false;
	    }

		$.ajax({
	      type: "POST",
	      url: url,
	      data: dataForm,
	      contentType: false, 
	      processData: false,
	      	beforeSend: function() {
	      		txt = $('.download-pdf').text();
	   			$('.download-pdf').text('Loading...');
	   			$('.errors').text("");
	        },
	        success: function(data) {                    
	        	$('.download-pdf').text(txt);  
	        	$('.download-pdf').attr('href', data.url);              
				window.location.href = data.url;
	        },
	        error: function (jqXHR, json) {
            	var errors = jqXHR.responseJSON;
			    for (var error in errors) {
			    	if(error == 'count') return;
			        $('.errors').append(errors[error] + '<br>');
			    }
			}
	    })
	}

	function generatePreview(data){
		var img = $('<img />', { 
		  id: 'poster-preview',
		  src: data.url,
		  alt: 'Preview - Poster'
		});
		$('.container-preview').html(img);
		$('.preview').removeClass('loading');
	}

	refreshForm();

};


// -----------------------------------------------------------
// MENU 
// -----------------------------------------------------------
var Menu = function() {
	this.hamburger = $('.menu-nav-button');
	this.hamburger.on('click', this.toggle.bind(this));
}

Menu.prototype.toggle = function(e) {
	e.preventDefault();

	$body.toggleClass('active-menu');
	if ($body.hasClass('active-menu')) {
		this.windowScroll = $window.scrollTop();
		$body.css('top', -this.windowScroll + 'px');
		$window.scrollTop(0);

	} else {
		$body.css('top', '');
		$window.scrollTop(this.windowScroll);
	}
}



// -----------------------------------------------------------
// PAGINATION 
// -----------------------------------------------------------
var Pagination = function() {
	this.element = $('.pagination');
	if (!this.element.length) return;

	this.target = $('.js-pagination-target');
	if (!this.target.length) return;

	var items = this.element.find('li');
	if (!items.length) return;

	// var index = items.filter('.active').index() + 1;
	// items.splice(0, index);

	// var str;
	// this.queue = [];
	// items.find('a').each(function(index, item) {
	// 	item = $(item);
	// 	str = item.attr('href');
	// 	if (!this.queue.has(str)) this.queue.push(str);
	// }.bind(this));
    //
	// if (!this.queue.length) return;

	this.parse();
	if (!this.next) return;

	this.element.remove();

	this.loader = $('<div class="js-loader section-sub"></div>');

	this.top = Number.MAX_VALUE;

	this.resizeBound = this.resize.bind(this);
	this.scrollBound = this.scroll.bind(this);
	$window.on('resize', this.resizeBound);
	$window.on('scroll', this.scrollBound);

	this.resize();
}

Pagination.prototype.parse = function(element) {
	if (!element) element = this.element;
	var item = element.find('.active').next('li').find('a');
	this.next = item.length ? item.attr('href') : null;
}

Pagination.prototype.resize = function() {
	this.offset = $window.height() * 0.8;
	this.top = this.target.offset().top + this.target.outerHeight();
	this.scroll();
}

Pagination.prototype.scroll = function() {
	if (!this.loading && $window.scrollTop() + this.offset >= this.top) {
		this.loading = true;
		this.target.after(this.loader);

		$.get(this.next, function(data) {
			this.loader.detach();

			data = $(data);
			this.parse(data.find('.pagination'));

			data = data.find('.js-pagination-target').children().addClass('js-transparent js-fade');
			this.target.append(data);
			data.each(function(index, item) {
				item = $(item);
				item.execute(index * 50, function() {
					$(this).removeClass('js-transparent').execute(500, function() {
						$(this).removeClass('js-fade');
					});
				});
			}.bind(this));

			if (this.next) {
				this.loading = false;
				this.resize();

			} else {
				$window.off('resize', this.resizeBound);
				$window.off('scroll', this.scrollBound);
			}

		}.bind(this));
	}
}



// -----------------------------------------------------------
// DISTRICTS
// -----------------------------------------------------------
var Districts = function() {
	this.element = $('.districts-map');
	if (!this.element.length) return;
	
	var mapOptions = {
		'center': new google.maps.LatLng(52.2129919, 5.2793703),
		'zoom': 7
		// 'mapTypeId': google.maps.MapTypeId.ROADMAP,
		// 'streetViewControl': false,
		// 'styles': [
			// {
			// 	"stylers": [
			// 		{ "saturation": -100 }
			// 	]
			// },{
			// 	"stylers": [
			// 	{ "hue": "#ff9100" },
			// 	{ "gamma": 0.44 },
			// 	{ "saturation": 6 },
			// 	{ "lightness": 5 }
			// 	]
			// }
		// ]
	};
	this.map = new google.maps.Map(this.element[0], mapOptions);

	var marker;
	this.items = this.element.siblings('.district-info');
	this.items.each(function(index, item) {
		item = $(item);

		item.prepend('<button type="button" class="close">X</button>');
		item.find('.close').on('click', function(e) {
			$(e.currentTarget).closest('.district-info').removeClass('active');
		});

		marker = new google.maps.Marker({
			'position': new google.maps.LatLng(item.attr('data-latitude'), item.attr('data-longitude')),
			'map': this.map,
			'icon': {
				'url': '/css/images/google-maps-marker.png',
				'size': new google.maps.Size(20, 32)
			}
		});

		google.maps.event.addListener(marker, 'click', function() {
			this.items.removeClass('active');
			item.addClass('active');
		}.bind(this));

	}.bind(this));

	$('.district-list .district-list-item a').on('click', function(e) {
		e.preventDefault();
		this.items.removeClass('active');
		$($(e.currentTarget).attr('href')).addClass('active');
	}.bind(this));

	this.cover = $('<div style="position:absolute;left:0;right:0;top:0;bottom:0;z-index:999;"></div>');
	this.element.prepend(this.cover);
	this.cover.on('click', this.coverToggle.bind(this));

	this.coverToggleBound = this.coverToggle.bind(this);
	this.coverToggle();
}

Districts.prototype.coverToggle = function(e) {
	if (e && e.type == 'click') {
		$window.on('scroll', this.coverToggleBound);
		this.cover.css('display', 'none');

	} else {
		$window.off('scroll', this.coverToggleBound);
		this.cover.css('display', '');
	}
}



// -----------------------------------------------------------
// MUNICIPALITIES
// -----------------------------------------------------------
var Municipalities = function() {
	this.element = $('.wiki-gemeente-list-stretch');
	if (!this.element.length) return;

	this.clearBound = this.clear.bind(this);

	this.items = this.element.find('li');
	this.empty = this.element.siblings('.wiki-gemeente-empty');

	this.filter = new Filters('.wiki-gemeente-search input', '.wiki-gemeente-li');
	this.filter.on('change', this.change.bind(this));
	this.change();

	$document.on('keydown', this.key.bind(this));
}

Municipalities.prototype.change = function() {
	this.clear();
	this.itemsVisible = this.items.filter(':visible');
	this.empty.toggle(!this.itemsVisible.length);
}

Municipalities.prototype.clear = function() {
	if (this.item) {
		this.item.removeClass('select');
		this.item.find('.wiki-gemeente-button').blur();
		this.item = null;
	}
	this.index = -1;
	this.items.off('mouseover', this.clearBound);
}

Municipalities.prototype.key = function(e) {
	if (e.keyCode == 38 || e.keyCode == 40) {
		e.preventDefault();
		if (this.itemsVisible.length) {
			var previous = this.item;
			if (previous) previous.removeClass('select');

			this.index += e.keyCode - 39;
			if (this.index < -1) {
				this.index = this.itemsVisible.length - 1;
			} else if (this.index >= this.itemsVisible.length) {
				this.index = -1;
			}

			if (this.index == -1) {
				this.filter.input.focus();
			} else {
				if (!previous) this.itemsVisible.on('mouseover', this.clearBound);
				this.item = this.itemsVisible.eq(this.index);
				this.item.addClass('select');
				this.item.find('.wiki-gemeente-button').focus();
			}
		}
	}
}



// -----------------------------------------------------------
// ACCORDION
// -----------------------------------------------------------
var Accordion = function () {
    this.init.apply(this, arguments);
};

Accordion.prototype.default_settings = {
    'containerClass': 'acc-container',
    'triggerClass': 'acc-trigger',
    'contentClass': 'acc-content',
    'activeClass': 'acc-active',
    'speed': 300
};

Accordion.prototype.init = function (node, settings) {
    this.settings = $.extend({}, this.default_settings, settings);
    this.container = $(node);
    this.trigger = this.container.find('.' + this.settings.triggerClass);
    this.content = this.container.find('> .' + this.settings.contentClass);
    this.active = this.settings.activeClass;
    this.speed = this.settings.speed;
    $window = $(window);

    this.checkStatus();

    this.checkStatusHandler = this.checkStatus.bind(this);
    this.trigger.bind('click', this.checkStatusHandler);
    this.bindClick = false;

    if (this.container.hasClass('is-desktop')) {
        $window.bind('resize', this.killAccordion.bind(this));
        this.killAccordion();
    }
};

Accordion.prototype.killAccordion = function () {
    if ($window.width() >= 800) {
        this.container.addClass(this.active);
        this.content.show();
        this.trigger.unbind('click', this.checkStatusHandler);
        this.bindClick = true;
    } else if (this.bindClick) {
        this.bindClick = false;
        this.trigger.bind('click', this.checkStatusHandler);
    }
};

Accordion.prototype.checkStatus = function (e) {
    if (e) {

        if ($(e.target).prop('tagName').toLowerCase() != 'label') {
            e.preventDefault();
        }

        this.container = $(e.currentTarget).closest('.' + this.settings.containerClass);
        this.content = this.container.find('> .' + this.settings.contentClass);

        if (this.container.hasClass(this.active)) {
            this.hideContent()
        } else {
            this.showContent();
        }
    } else {
        if (this.container.hasClass(this.active)) {
            this.content.show();
        } else {
            this.content.hide();
        }
    }
};

Accordion.prototype.showContent = function () {
    this.content.slideDown(this.speed, function () {
        this.container.addClass(this.active);
    }.bind(this));
};

Accordion.prototype.hideContent = function () {
    this.content.slideUp(this.speed, function () {
        this.container.removeClass(this.active);
    }.bind(this));
};

$.fn.accordion = function (settings) {
    $(this).each(function (index, item) {
        item = $(item);
        var instance = item.data('accordion');
        if (!instance) {
            item.data('accordion', new Accordion(this, settings))
        }
    })
};



// -----------------------------------------------------------
// TABS
// -----------------------------------------------------------
var Tabs = function() {
	this.links = $('.js-tab');
	if (!this.links.length) return;

	var target;
	this.targets = $([]);
	this.links.each(function (index, item) {
		item = $(item);
		target = $(item.attr('href'));
		if (target.length) {
			item.data('tabTarget', target);
			this.targets = this.targets.add(target);
		}
	}.bind(this));
	this.links.on('click', this.change.bind(this));
}

Tabs.prototype.change = function(e) {
	e.preventDefault();
	var link = $(e.currentTarget);
	var target = link.data('tabTarget');

	this.links.removeClass('active');
	this.targets.removeClass('wiki-tab-content-active');
	
	link.addClass('active');
	target.addClass('wiki-tab-content-active');
}



// -----------------------------------------------------------
// SEARCH
// -----------------------------------------------------------
var Search = function() {
	this.element = $('#search');
	if (!this.element.length) return;

	this.results = $('.js-search-results');
	if (!this.results.length) return;

	this.loadBound = this.load.bind(this);
	this.loadSuccessBound = this.loadSuccess.bind(this);
	this.loadCompleteBound = this.loadComplete.bind(this);

	this.element.on('keyup change', this.change.bind(this));
}

Search.prototype.change = function(e) {
	clearTimeout(this.changeTimeout);
	this.changeTimeout = setTimeout(this.loadBound, 300);
}

Search.prototype.load = function() {
	if (this.ajax) {
		this.ajax.abort();
	}

	this.results.empty();

	var val = this.element.val();
	if (!val || val.length < 3) {
		$body.removeClass('search-active');
		this.element.removeClass('loading');

	} else {
		$body.addClass('search-active');
		this.element.addClass('loading');

		this.ajax = $.ajax({
			'url': '/wiki/dashboard/search',
			'type': 'POST',
			'data': {
				'searchTerm': val
			},
			'dataType': 'text',
			'success': this.loadSuccessBound,
			'complete': this.loadCompleteBound
		});
	}
}

Search.prototype.loadSuccess = function(data) {
	this.results.html(data);
}

Search.prototype.loadComplete = function() {
	this.element.removeClass('loading');
}



// -----------------------------------------------------------
// OPEN NEW WINDOW
// -----------------------------------------------------------
$.fn.openNewWindow = function() {
	return $(this).each(function(index, item) {
		item = $(item);
		if (!item.data('OpenNewWindow')) {
			item.data('OpenNewWindow', new OpenNewWindow(item));
		}
	}.bind(this));
}

var OpenNewWindow = function(element) {
	this.element = element;

	this.width = 600;
	this.height = 400;
	this.options = 'status=no,toolbar=no,menubar=no,scrollbars=no,location=no,directories=no,resizable=yes,height=' + this.height + ',width=' + this.width;
	this.url = element.attr('href');

	this.element.on('click', this.open.bind(this));
}

OpenNewWindow.prototype.open = function(e) {
	e.preventDefault();

	var left = window.screenX + (window.screen.width / 2) - (this.width / 2);
	var top = window.screenY + (window.screen.height / 2) - (this.height / 2);
	var options = this.options + ',left=' + left + ',top=' + top;

	window.open(this.url, 'sharer', options);
}


// -----------------------------------------------------------
// SUBMIT LOADER
// -----------------------------------------------------------
function submitLoader(e) {
	$(e.target).hide();
	$('.js-loader-animation').show();
}
