// -----------------------------------------------------------
// FILTERS v1.2
// -----------------------------------------------------------
var Filters = function (input, output) {
    this.input = this.objectSelect(input);
    if (!this.input || !this.input.length) return;

    this.events = {};

    this.output = this.objectSelect(output);
    if (!this.output || !this.output.length) return;

    this.delayer = $('<div></div>');

    if (this.input.is('form')) {
        this.formData = {
            'url': this.input.attr('action'),
            'type': this.input.attr('method') || 'GET',
            'success': this.formSuccess.bind(this)
        };

        this.input.on('submit', this.prevent.bind(this));
        this.input.find('[type="submit"]').remove();
        this.input.find('input, select, textarea').on('change keyup', this.formChange.bind(this));

    } else {
        this.input.on('change keyup', this.searchChange.bind(this));
        this.input.closest('form').on('submit', this.prevent.bind(this));

        this.searchItems = [];
        this.output.each(function (index, item) {
            item = $(item);
            this.searchItems.push({
                'element': item,
                'value': item.text().toLowerCase()
            });
        }.bind(this));
    }
};

Filters.prototype.prevent = function (e) {
    e.preventDefault();
};

Filters.prototype.on = function (name, callback) {
    if (!this.events[name]) {
        this.events[name] = [];
    }
    this.events[name].push(callback);
};

Filters.prototype.trigger = function (name) {
    var event = this.events[name];
    if (event) {
        var i, callback;
        for (i = 0; i < event.length; i++) {
            event[i]();
        }
    }
};

Filters.prototype.objectSelect = function (object) {
    if (!object) return false;
    if (object instanceof $) {
        return object;
    } else {
        return $(object);
    }
};

Filters.prototype.searchChange = function (e) {
    var delay = (e.type == 'keyup' ? 300 : 0);
    this.delayer.execute(this, delay, function () {
        var value = this.input.val().toLowerCase().trim();
        if (this.value != value) {
            this.value = value;
            var i, j, item, boo, values = value.split(' ');
            for (i = 0; i < this.searchItems.length; i++) {
                item = this.searchItems[i];
                boo = true;
                for (j = 0; j < values.length; j++) {
                    if (!item.value.has(values[j])) boo = false;
                }
                item.element.toggle(boo);
            }

            this.trigger('change');
        }
    });
};

Filters.prototype.formChange = function () {
    if (this.formAjax) this.formAjax.abort();
    this.delayer.execute(this, function () {
        this.output.empty();
        this.formData.data = this.input.serialize();
        this.formAjax = $.ajax(this.formData);
    });
};

Filters.prototype.formSuccess = function (data) {
    this.formAjax = null;
    this.output.html(data);
    this.trigger('change');
    $('.js-lightbox').lightbox();
};
