$(function() {
    let el;
    $('[data-lightbox]').on('click', function(e) {
        el = $(this);
        e.preventDefault();

        $.fancybox.open({
            src: el.attr('href'),
            type : 'ajax',
            afterLoad: function(ed) {
                if('lightboxFull' in el.data()) {
                    $('.c-result__overlay').addClass('c-result__overlay--fullscreen')
                }
            }
        });
    });
});

var input, list;
$(function() {
    input = $('.js-municipality');
    list = $('.c-municipality-search__result');
    input.on('keyup', handleSearch);
    $(window).on('click', hideResults);
});

var items;
var handleSearch = debounce(function() {
    list.html('');
    $.ajax({
        method: "GET",
        url: input.data('url'),
        data: { s: input.val() }
    }).done(function(result) {    

        if(result.length) {
            for(let i=0; i<result.length; i++) {
                let row = result[i];
                $('<a href="'+row.url+'">'+row.name+'</a>').appendTo(list);
            }
            
            items = list.find('a');
            items.on('click', handleClick);
        } else {
            $('<a>Geen resultaten gevonden...</a>').appendTo(list);
        }

        
        list.addClass('is-active');
        
    });
    
}, 350);

var handleClick = function(e) {
    e.preventDefault();
    items.off('click', handleClick);
    hideResults();
    openLightbox($(this).attr('href'), 't-results');	
}

var hideResults = function(e) {
    list.removeClass('is-active');
}


// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};