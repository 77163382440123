// -----------------------------------------------------------
// LIGHTBOX
// -----------------------------------------------------------
(function(){
	$.fn.lightbox = function(options){
		var element = $(this);
		if(!element.length) return element;
		element.unbind('click', $.fn.lightboxShow);
		element.click($.fn.lightboxShow);
		element.data('options', options || {});
		return element;
	}

	$.fn.lightboxShow = function(e){
		windowResize();
		if(WIDESCREEN && !e.button && !e.ctrlKey && !e.metaKey){

			e.preventDefault();

			$.lightboxOptions = $(this).data('options');
			$.lightboxUrl = this.href;

			var lightbox = $('#lightbox');
			if(lightbox.length){
				lightbox.stop(true).addClass('js-transparent').delay(400).queue(function(){
					$(this).remove();
					$.fn.lightboxDataLoad();
				});

			}else{
				var overlay = $('<div id="lightbox-overlay" class="js-fade js-transparent"></div>');
				$body.prepend(overlay);
				overlay.delay(0).queue(function(){
					$(this).removeClass('js-transparent');
				});
				$.fn.lightboxTabindex(false);
				$.fn.lightboxDataLoad();
			}
		}
	}

	$.fn.lightboxDataLoad = function(){
		$.ajax({
			url:$.lightboxUrl,
			cache:false,
			type:'GET',
			data: {section:'lightbox'},
			success:$.fn.lightboxDataLoaded,
			complete:null
		});
	}

	$.fn.lightboxDataLoaded = function(data){
		var lightbox = $('<div id="lightbox" class="js-fade js-transparent"><div id="lightbox-content-container">' + data + '</div></div>');
		$body.prepend(lightbox);

		var close =$('<button type="button" class="close lightbox-close">Sluiten</button>');
		$('.download-formats-container').append(close);

		$('.js-back-button').hide();
		

		$('#lightbox-content-container').children().addClass('lightbox-content');

		$('#lightbox-overlay').click($.fn.lightboxHide);
		lightbox.find('.lightbox-close').andSelf().click($.fn.lightboxHide);
		$html.bind('keydown', $.fn.lightboxHide);

		var img = lightbox.find('img').first();
		if(!img.length){
			$.fn.lightboxLoadComplete();
		}else{
			img.load($.fn.lightboxImgLoaded);
		}
	}

	$.fn.lightboxImgLoaded = function(){
		var img = $(this);
		img.unbind('load', $.fn.lightboxImgLoaded);
		var par = img.parent();
		par.css('display','block');
		$.fn.lightboxLoadComplete();
		par.css('display','');
	}

	$.fn.lightboxLoadComplete = function(){
		var lightbox = $('#lightbox');

		var h = lightbox.height();
		var t = $window.scrollTop();
		var n = t + ($window.height() / 2) - (h / 2);
		n = Math.max(t + 50, n);
		n = Math.min($document.height() - h - 50, n);
		n = Math.max(50, n);
		lightbox.css('top', n + 'px');
		
		lightbox.removeClass('js-transparent');
		if($.lightboxOptions.complete != null) $.lightboxOptions.complete();
	}

	$.fn.lightboxHide = function(e){
		var keydown = (e.type == 'keydown');
		if((keydown && e.keyCode == 27) || (!keydown && (this.id != 'lightbox' || !$('#lightbox-content-container').find(e.target).length))){
			$.fn.lightboxTabindex(true);
			$('#lightbox-overlay, #lightbox').stop(true).addClass('js-transparent').delay(400).queue(function(){
				$(this).remove();
			});
		}
	}

	$.fn.lightboxTabindex = function(enable){
		var n, elements = $body.find('a, button, input, select, textarea');

		if(enable){
			elements.each(function(index, item){
				item = $(item);
				n = item.attr('data-tabindex');
				item.removeAttr('data-tabindex');
				if(n){
					item.attr('tabindex', n);
				}else{
					item.removeAttr('tabindex');
				}
			});

		}else{
			elements.each(function(index, item){
				item = $(item);
				n = item.attr('tabindex');
				if(n) item.attr('data-tabindex', n);
				item.attr('tabindex', '-1');
			});
		}
	}

})();