$(function() {
    //MagicScroll
    var blocks = $('.js-scroll'), scroll, scrollItem;
    blocks.each(function(index, item) {  
        scrollItem = $(item);      
        scroll = new magicScroll(scrollItem);
    });
});


// class magicScroll {
//     constructor(item, debug = false) {
//         this.debug = debug;
//         this.item = item;
//         this.resize();
//         $(window).on('resize', this.resize.bind(this));
//     }

//     initScrollMagic() {
//         this.controller = new ScrollMagic.Controller();
//         this.scene = new ScrollMagic.Scene(
//             { 
//                 triggerElement: this.item[0], 
//                 duration: this.height,
//                 offset: -300
//             }
//         );
//         this.scene.on('enter', () => {
//             this.item.addClass('active');
//         })
//         this.scene.addTo(this.controller);
//         if(this.debug) {
//             this.scene.addIndicators();
//         }
//     }

//     resize() {
//         this.height = this.item.outerHeight();
//         if(typeof this.scene == 'object') {
//             this.controller.destroy(true);
//             this.controller = null;
//             this.scene.destroy(true);
//             this.scene = null;
//         }
//         this.initScrollMagic();
//     }

// }

'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var magicScroll = function () {
    function magicScroll(item) {
        var debug = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        _classCallCheck(this, magicScroll);

        this.debug = debug;
        this.item = item;
        this.resize();
        $(window).on('resize', this.resize.bind(this));
    }

    _createClass(magicScroll, [{
        key: 'initScrollMagic',
        value: function initScrollMagic() {
            var _this = this;

            this.controller = new ScrollMagic.Controller();
            this.scene = new ScrollMagic.Scene({
                triggerElement: this.item[0],
                duration: this.height,
                offset: -300
            });
            this.scene.on('enter', function () {
                _this.item.addClass('active');
            });
            this.scene.addTo(this.controller);
            if (this.debug) {
                this.scene.addIndicators();
            }
        }
    }, {
        key: 'resize',
        value: function resize() {
            this.height = this.item.outerHeight();
            if (_typeof(this.scene) == 'object') {
                this.controller.destroy(true);
                this.controller = null;
                this.scene.destroy(true);
                this.scene = null;
            }
            this.initScrollMagic();
        }
    }]);

    return magicScroll;
}();